<template >
  <v-container>
    <v-btn
      color="success"
      v-show="verBotonTransferirDesatendida"
      fab
      x-large
      @click="verDialogoTransferir()"
    >
      <v-icon>mdi-dialpad</v-icon>
    </v-btn>

    <v-dialog
      transition="dialog-bottom-transition"
      persistent
      v-model="vertranferencia"
      width="395"
    >
      <v-card>
        <v-container>
          <v-row>
            <v-col class="col-md-6 offset-md-3"> </v-col>
          </v-row>
        </v-container>
        <numericpad :onInput="onInput" :size="3" />

        <v-container>
          <v-row class="justify-center">
            <v-btn color="error" fab x-large @click="cerrar()">
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-row>
        </v-container>

        <v-card-actions class="justify-center"> </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :right="true"
      :timeout="3000"
      :color="snack_color"
    >
      {{ snack_text }}
      <v-btn dark text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </v-container>
</template>


<script>
import numericpad from "./numeric-keypad.vue";

export default {
  components: {
    numericpad,
  },
  name: "transferir",

  data: () => ({
    snackbar: false,
    snack_color: "",
    snack_text: "",
    extensiontransferencia: "",
    vertranferencia: false,
    verBotonTransferirDesatendida: true,
    verBotonTransferirAtendida: true,
  }),

  methods: {
    onInput(key) {
      this.extensiontransferencia = (this.extensiontransferencia + key).slice(
        0,
        this.maxLength
      );
    },
    vaciar() {
      this.extensiontransferencia = this.extensiontransferencia.slice(0, -1);
    },

    verDialogoTransferir() {
      this.$store.dispatch("settecladoDtmf", true);

      this.vertranferencia = true;
    },

    cerrar() {
      this.vertranferencia = false;
      this.extensiontransferencia = "";
    },
    go(msg, color) {
      this.snack_text = msg;
      this.snack_color = color;
      this.snackbar = true;
    },
  },
  computed: {
    VtecladoDtmf() {
      return this.$store.getters.gettecladoDtmf;
    },
  },
  watch: {
    VtecladoDtmf(value) {
      if (!value) {
        this.cerrar();
      }
    },
  },
};
</script>
<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>


<template>
  <v-container>
    <v-row justify="center">
      <v-col :md="this.size">
        <v-btn
          x-large
          fab
          @click="clickTeclado(1)"
          @mousedown="dialTone(697.0, 1209.0)"
          @mouseup="stop()"
          data-freq="697.0, 1209.0"
        >
          1
        </v-btn>
      </v-col>
      <v-col :md="this.size">
        <v-btn
          x-large
          fab
          @click="clickTeclado(2)"
          @mousedown="dialTone(697.0, 1336.0)"
          @mouseup="stop()"
          data-freq="697.0, 1336.0"
        >
          2
        </v-btn>
      </v-col>
      <v-col :md="this.size">
        <v-btn
          x-large
          fab
          @click="clickTeclado(3)"
          @mousedown="dialTone(697.0, 1477.0)"
          @mouseup="stop()"
          data-freq="697.0, 1477.0"
        >
          3
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col :md="this.size">
        <v-btn
          x-large
          fab
          @click="clickTeclado(4)"
          @mousedown="dialTone(770.0, 1209.0)"
          @mouseup="stop()"
          data-freq="770.0, 1209.0"
        >
          4
        </v-btn>
      </v-col>
      <v-col :md="this.size">
        <v-btn
          x-large
          fab
          @click="clickTeclado(5)"
          @mousedown="dialTone(770.0, 1336.0)"
          @mouseup="stop()"
          data-freq="770.0, 1336.0"
        >
          5
        </v-btn>
      </v-col>
      <v-col :md="this.size">
        <v-btn
          x-large
          fab
          @click="clickTeclado(6)"
          @mousedown="dialTone(770.0, 1477.0)"
          @mouseup="stop()"
          data-freq="770.0, 1477.0"
        >
          6
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col :md="this.size">
        <v-btn
          x-large
          fab
          @click="clickTeclado(7)"
          @mousedown="dialTone(852.0, 1209.0)"
          @mouseup="stop()"
          data-freq="852.0, 1209.0"
        >
          7
        </v-btn>
      </v-col>
      <v-col :md="this.size">
        <v-btn
          x-large
          fab
          @click="clickTeclado(8)"
          @mousedown="dialTone(852.0, 1336.0)"
          @mouseup="stop()"
          data-freq="852.0, 1336.0"
        >
          8
        </v-btn>
      </v-col>
      <v-col :md="this.size">
        <v-btn
          x-large
          fab
          @click="clickTeclado(9)"
          @mousedown="dialTone(852.0, 1477.0)"
          @mouseup="stop()"
          data-freq="852.0, 1477.0"
        >
          9
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col :md="this.size">
        <v-btn
          x-large
          fab
          @click="clickTeclado('*')"
          @mousedown="dialTone(941.0, 1209.0)"
          @mouseup="stop()"
          data-freq="941.0, 1209.0"
        >
          <v-icon x-small>mdi-star</v-icon>
        </v-btn>
      </v-col>
      <v-col :md="this.size">
        <v-btn
          x-large
          fab
          @click="clickTeclado(0)"
          @mousedown="dialTone(941.0, 1336.0)"
          @mouseup="stop()"
          data-freq="941.0, 1336.0"
        >
          0
        </v-btn>
      </v-col>
      <v-col :md="this.size">
        <v-btn
          x-large
          fab
          @click="clickTeclado('#')"
          @mousedown="dialTone(941.0, 1477.0)"
          @mouseup="stop()"
          data-freq="941.0, 1477.0"
        >
          #
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "numeric-keypad",
  data: () => ({
    contextClass: null,
    context: null,
    oscillator1: null,
    oscillator2: null,
    gainNode: null,
  }),
  props: {
    keypadClass: { type: String, default: "keypad-class", required: false },
    onInput: { type: Function, required: true },
    size:{type:Number}
    
  },
  mounted() {
    var vm = this;
    vm.contextClass =
      window.AudioContext ||
      window.webkitAudioContext ||
      window.mozAudioContext ||
      window.oAudioContext ||
      window.msAudioContext;
    if (vm.contextClass) {
      vm.context = new vm.contextClass();
    }
  },
  methods: {
    clickTeclado(tecla) {
      this.onInput(tecla);

      this.$store.getters.getinstanciaPhone.dtmfEnvio(tecla);
    },
    dialTone(freq1, freq2) {
      var vm = this;
      vm.oscillator1 = vm.context.createOscillator();
      vm.oscillator1.type = 0;
      vm.oscillator1.frequency.value = freq1;
      vm.gainNode = vm.context.createGain
        ? vm.context.createGain()
        : vm.context.createGainNode();
      vm.oscillator1.connect(vm.gainNode, 0, 0);
      vm.gainNode.connect(vm.context.destination);
      vm.gainNode.gain.value = 0.1;
      vm.oscillator1.start ? vm.oscillator1.start(0) : vm.oscillator1.noteOn(0);
      vm.oscillator2 = vm.context.createOscillator();
      vm.oscillator2.type = 0;
      vm.oscillator2.frequency.value = freq2;
      vm.gainNode = vm.context.createGain
        ? vm.context.createGain()
        : vm.context.createGainNode();
      vm.oscillator2.connect(vm.gainNode);
      vm.gainNode.connect(vm.context.destination);
      vm.gainNode.gain.value = 0.1;
      vm.oscillator2.start ? vm.oscillator2.start(0) : vm.oscillator2.noteOn(0);
    },
    stop() {
      var vm = this;
      vm.oscillator1.disconnect();
      vm.oscillator2.disconnect();
    },
  },
};
</script>
<style>
.v-btn.v-size--x-large {
  font-size: 1.6rem;
}
</style>
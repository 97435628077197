/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import adapter from 'webrtc-adapter';
import hat from 'hat';
import { Howl } from 'howler';
// import { message } from 'antd';
import Janus from './janus';
import config from './config';
import tono from './ringtone.wav';



import store from '../../../store';

export default class PhoneCommander {
  constructor(
    userConfig,
    debug, videoTagRef,
    onIncomingCall,
    cleanUp,
  ) {
    this.debug = debug;
    this.userConfig = userConfig;
    this.janus = undefined;
    this.sipCall = undefined;
    this.jsep = undefined;
    this.registered = "";
    this.nombreLLamadaEntrante = "";
    this.registro = "iniciado";
    this.llamada = "iniciado";
    this.llamadaEstado = "false"
    this.videoTagRef = videoTagRef;
    this.onIncomingCall = onIncomingCall;
    this.cleanUp = cleanUp; // when remote or local hangup callback
    this.offerlessInvite = false;
    this.doAudio = true;
    this.doVideo = true;
    this.ringtone = new Howl({
      src: [tono],
      loop: true,
    });
  }

  sessionCreated(pluginHandle) {
    this.sipCall = pluginHandle;
    const register = {
      request: 'register',
      username: this.userConfig.sipIdentity,
      display_name: this.userConfig.displayName,
      proxy: this.userConfig.sipRegistrar,
      authuser: this.userConfig.username,
      secret: this.userConfig.secret,
    };
    this.sipCall.send({ message: register });
    this.registered = true;

  }

  onMessage(msg, jsep) {
    this.jsep = jsep;
    Janus.debug(' ::: Got a message :::', msg);
    const {
      error, callId, result,
    } = msg;

    if (error) {
      // Reset status
      if (this.registered) this.sipCall.hangup();
      alert(error)
      console.log(error)
      // message.error(error);
      return;
    }

    const { event } = result;

    if (!result || !event) return;

    if (event === 'registration_failed') {
      Janus.warn(`Registration failed: ${result.code} ${result.reason}`);
      // message.error(`Registration failed: ${result.code} ${result.reason}`);
      //alert(`Registration failed: ${result.code} ${result.reason}`)
      //console.log(`Registration failed: ${result.code} ${result.reason}`);
      this.registro = false;
      store.dispatch("setregistro", false);
      //store.commit("SET_REGISTRO", false);
      return;
    }

    if (event === 'registered') {
      // 
      Janus.log(`Successfully registered as ${result.username}!`);
      // alert(`Successfully registered as ${result.username}!`);
      if (!this.registered) {
        this.registered = true;
      }
      this.registro = true;
      //store.commit("SET_REGISTRO", true);
      store.dispatch("setregistro", true);



      return;
    }

    if (event === 'calling') {
      Janus.log('Waiting for the peer to answer...');
    }

    if (event === 'incomingcall') this.incomingCall(msg.call_id, result, jsep);

    if (event === 'progress' && this.jsep) {
      this.sipCall.handleRemoteJsep({
        jsep: this.jsep,
        error: (e) => {
          console.log(e);
          this.doHangup();
        },
      });
    }


    if (event === 'notify') {
      store.commit("SET_LLAMADAESTADO", "transferenciaNotificada");
    }


    if (event === 'hangup') {
      store.dispatch("setverColgar", false);
      store.dispatch("setllamadaEstado", "intermedio1");
      store.dispatch("setllamadaEstado", "intermedio2");

      store.dispatch("setllamadaEstado", "hangup");
      this.llamadaEstado = "hangup"
    }

    if (event === 'accepted') {
      //store.commit("SET_CALLID", msg.call_id);
      //store.commit("SET_VERCOLGAR", true);
      store.dispatch("setverColgar", true);

      //store.commit("SET_LLAMADAESTADO", "intermedio");
      //store.commit("SET_LLAMADAESTADO", "accepted");
      store.dispatch("setllamadaEstado", "intermedio");
      store.dispatch("setllamadaEstado", "accepted");
      this.llamadaEstado = "accepted"

      if (this.jsep) {
        this.sipCall.handleRemoteJsep({
          jsep: this.jsep,
          error: () => this.doHangup(),
        });
      }
      this.sipCall.callId = msg.call_id;
    }

    if (event === 'updatingcall') {
      // We got a re-INVITE: while we may prompt the user (e.g.,
      // to notify about media changes), to keep things simple
      // we just accept the update and send an answer right away
    }
  }

  onLocalStream(stream) {
    if (this.sipCall.webrtcStuff.pc.iceConnectionState === 'completed'
      && this.sipcall.webrtcStuff.pc.iceConnectionState === 'connected') {
      // conexion establecida
      this.onWaitingResponse(false);
    }
  }

  onRemoteStream(stream) {
    Janus.debug(' ::: Got a remote stream :::', stream);
    const videoTracks = stream.getVideoTracks();
    if (!videoTracks || videoTracks.length === 0) Janus.log('NO VIDEO __ SOLO AUDIO');
    var video = this.videoTagRef
    video.srcObject = stream;
    video.onloadedmetadata = function (e) { video.play(); };
  }

  onCleanUp() {
    Janus.log(' ::: Got a cleanup notification :::');
    if (this.ringtone.playing()) this.ringtone.stop();
    if (this.sipCall && this.sipCall.callId) this.sipCall = { ...this.sipCall, callId: null };
    this.llamada = "rechazada"
    //this.cleanUp();
  }

  attachPlugin() {
    this.janus.attach({
      plugin: 'janus.plugin.sip',
      opaqueId: `siptest-${hat()}`,
      success: this.sessionCreated.bind(this),
      error: (error) => Janus.error('  -- Error attaching plugin...', error),
      consentDialog: () => Janus.log('consent dialog'),
      iceState: (state) => Janus.log(`ICE state changed to ${state}`),
      mediaState: (medium, on) => Janus.log(`Janus ${on ? 'started' : 'stopped'} receiving our ${medium}`),
      webrtcState: (on) => Janus.log(`Janus says our WebRTC PeerConnection is ${on ? 'up' : 'down'} now`),
      onmessage: this.onMessage.bind(this),
      onlocalstream: this.onLocalStream.bind(this),
      onremotestream: this.onRemoteStream.bind(this),
      oncleanup: this.onCleanUp.bind(this),
    });
    this.llamada = "attachPlugin"
  }

  createSession() {
    if (!Janus.isWebrtcSupported()) {
      console.error('No WebRTC support...');
      return;
    }
    const janusInstance = new Janus({
      server: config.webrtcServer,
      success: this.attachPlugin.bind(this),
      error: (error) => Janus.error(error),
      destroyed: () => console.log('destroyed'),
    });
    this.janus = janusInstance;
  }

  incomingCall(callId, {
    username, referred_by, srtp,
  }, jsep) {
    Janus.log(`Incoming call from ${username}!`);
    this.nombreLLamadaEntrante = `${username}`
    //store.commit("SET_NOMBRELLAMADAENTRANTE", this.nombreLLamadaEntrante);
    store.dispatch("setnombreLLamadaEntrante", this.nombreLLamadaEntrante);

    this.sipCall.callId = callId

    if (jsep) {
      // What has been negotiated?
      this.doAudio = (jsep.sdp.indexOf('m=audio ') > -1);
      this.doVideo = (jsep.sdp.indexOf('m=video ') > -1);
      Janus.debug(`Audio ${this.doAudio ? 'has' : 'has NOT'} been negotiated`);
      Janus.debug(`Video ${this.doVideo ? 'has' : 'has NOT'} been negotiated`);
    } else {
      Janus.log("This call doesn't contain an offer... we'll need to provide one ourselves");
      this.offerlessInvite = true;
      // In case you want to offer video when reacting to an offerless call, set this to true
      this.doVideo = false;
    }

    // Is this the result of a transfer?
    let transfer = '';
    if (referred_by) {
      transfer = ` (referred by ${referred_by})`;
      transfer = transfer.replace(new RegExp('<', 'g'), '&lt');
      // eslint-disable-next-line no-unused-vars
      transfer = transfer.replace(new RegExp('>', 'g'), '&gt');
    }

    // Any security offered? A missing "srtp" attribute means plain RTP
    let rtpType = '';
    if (srtp === 'sdes_optional') rtpType = ' (SDES-SRTP offered)';
    // eslint-disable-next-line no-unused-vars
    else if (srtp === 'sdes_mandatory') rtpType = ' (SDES-SRTP mandatory)';
    // Notify user
    this.ringtone.play();
   // this.onIncomingCall(username);
  }

  acceptCall() {
    const sipcallAction = (
      this.offerlessInvite ? this.sipCall.createOffer : this.sipCall.createAnswer
    );
    this.ringtone.stop()
    //if (this.ringtone.playing()) this.ringtone.stop();

    sipcallAction({
      jsep: this.jsep,
      media: { audio: this.doAudio, video: this.doVideo },
      success: (IncomingJsep) => {
        Janus.debug(`Got SDP ${this.jsep.type}! audio=${this.doAudio}, video=${this.doVideo}:`, IncomingJsep);
        const body = { request: 'accept' };
        this.sipCall.send({ message: body, jsep: IncomingJsep });
      },
      error: (error) => {
        Janus.error('WebRTC error:', error);
        const body = { request: 'decline', code: 480 };
        this.sipCall.send({ message: body });
      },
    });
  }

  declineCall() {
    if (this.ringtone.playing()) this.ringtone.stop();
    const body = { request: 'decline' };
    this.sipCall.send({ message: body });
  }

  transferirAtendida(extensiontransferencia) {
    var pbxtransferencia = this.userConfig.sipRegistrar.split(":")[1]
    var uriSip = 'sip:' + extensiontransferencia + '@' + pbxtransferencia
    var sipcall = this.sipCall.callId.toString()
    var jsep
    this.jsep = jsep;
    const body = {
      "request": "transfer",
      "uri": uriSip,
      "replace": sipcall
    };
    this.sipCall.send({ message: body });
  }

  transferirDesatendida(extensiontransferencia) {
    var pbxtransferencia = this.userConfig.sipRegistrar.split(":")[1]
    var jsep
    this.jsep = jsep;
    const body = {
      request: 'transfer',
      uri: 'sip:' + extensiontransferencia + '@' + pbxtransferencia,
    };
    this.sipCall.send({ message: body, jsep: jsep });
  }

  hold() {
    const body = {
      request: 'hold',
    };
    this.sipCall.send({ message: body });
  }

  unhold() {
    const body = {
      request: 'unhold',
    };
    this.sipCall.send({ message: body });
  }

  doHangup() {
    if (this.ringtone.playing()) this.ringtone.stop();
    const hangupMsg = { request: 'hangup' };
    this.sipCall.send({ message: hangupMsg });
    this.sipCall.hangup();
  }

  establishingCall(jsep) {
    Janus.debug('Got SDP!', jsep);
    this.jsep = jsep;
    const body = { request: 'call', uri: this.callingUri };
    this.sipCall.send({ message: body, jsep });
  }

  doCall(uri, doVideo, referId) {
    this.callingUri = this.userConfig.sipRegistrar.replace('sip:', `sip:${uri}@`);
    this.sipCall.createOffer({
      media: {
        audioSend: true,
        audioRecv: true,
        videoSend: false,
        videoRecv: false,
      },
      success: this.establishingCall.bind(this),
      error: (error) => {
        // message.error(`WebRTC error... ${error.message}`);
        console.log(`WebRTC error... ${error.message}`)
      },
    });
  }

  disconnect() {
    this.janus.destroy();
    this.registro = false;
    //store.commit("SET_REGISTRO", false);
    store.dispatch("setregistro", false);

  }

  connect() {
    Janus.init({
      debug: this.debug,
      dependencies: Janus.useDefaultDependencies({ adapter }),
      callback: this.createSession.bind(this),
    });
  }

  dtmfEnvio(tecla) {
    if (this.llamadaEstado == "accepted") {
      const body = {
        request: "dtmf_info",
        digit: tecla.toString()
      };
      this.sipCall.send({ message: body });
    }
  }

  isConnected() {
    return this.janus.isConnected()
  }

  reconnect() {
    this.janus.reconnect();
  }

  getSessionId() {
    return this.janus.getSessionId();
  }
}

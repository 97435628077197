<template >
  <v-container>
    <v-btn color="success" fab x-large @click="llamar()">
      <v-icon>mdi-phone</v-icon>
    </v-btn>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :right="true"
      :timeout="3000"
      :color="snack_color"
    >
      {{ snack_text }}
      <v-btn dark text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "llamar",
  data: () => ({ snackbar: false, snack_color: "", snack_text: "" }),
  computed: {},
  methods: {
    llamar() {
      this.$store.getters.getinstanciaPhone.doCall(this.itemEntrada);
      this.go("Llamando", "success");
      // this.$store.commit("SET_VERCOLGAR", true);
      // this.$store.commit("SET_INICIARBOTONERA", true);
      this.$store.dispatch("setiniciarBotonera", true);
      this.$store.dispatch("setverColgar", true);
    },
    go(msg, color) {
      this.snack_text = msg;
      this.snack_color = color;
      this.snackbar = true;
    },
  },
  props: {
    itemEntrada: {},
  },
};
</script>


<template >
  <v-row>
    <v-col class="justify-center">
      <v-chip v-html="formatTime(lapsedMilliseconds)"> </v-chip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "cronometro",

  data: () => ({
    state: "reset",
    startTime: Date.now(),
    currentTime: Date.now(),
    interval: null,
    intervalUpdateTime: 10,
  }),
  computed: {
    lapsedMilliseconds() {
      return this.currentTime - this.startTime;
    },
    VEstado() {
      return this.$store.getters.getstateCrono;
    },
  },

  watch: {
    VEstado(value) {
      if ((value = "reset")) {
        this.reset();
      }
      if ((value = "start")) {
        this.start();
      }
    },
  },
  methods: {
    formatTime(lapsed) {
      if (lapsed === undefined || lapsed === null) {
        lapsed = this.lapsedMilliseconds;
      }
      let time =
        this.formatHours(lapsed) +
        ":" +
        this.formatMinutes(lapsed) +
        ":" +
        this.formatSeconds(lapsed);

      return time;
    },
    formatHours(lapsed) {
      const hrs = Math.floor(lapsed / 1000 / 60 / 60);
      return hrs >= 10 ? hrs : "0" + hrs;
    },
    formatMinutes(lapsed) {
      const min = Math.floor((lapsed / 1000 / 60) % 60);
      return min >= 10 ? min : "0" + min;
    },
    formatSeconds(lapsed) {
      const sec = Math.floor((lapsed / 1000) % 60);
      return sec >= 10 ? sec : "0" + sec;
    },
    start() {
      this.startTime = Date.now();
      this.state = "started";
      this.startUpdateInterval();
    },

    reset: function () {
      this.stopUpdateInterval();
      this.state = "reset";
      this.startTime = Date.now();
      this.currentTime = Date.now();
      this.stopwatchIntervals = [];
      this.intervalKeyCounter = 1;
    },
    updateCurrentTime() {
      this.currentTime = Date.now();
    },
    startUpdateInterval() {
      this.interval = setInterval(
        this.updateCurrentTime,
        this.intervalUpdateTime
      );
    },
    stopUpdateInterval() {
      clearInterval(this.interval);
    },
  },
  props: {
    itemEntrada: {},
  },
};
</script>


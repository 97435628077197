<template>
  <v-container>
    <v-btn v-show="!verpausa" x-large fab color="success" @click="pausa()">
      <v-icon>mdi-phone-paused</v-icon>
    </v-btn>
    <v-btn color="warning" v-show="verpausa" x-large fab @click="reanudar()"
      ><v-icon>mdi-phone-paused</v-icon>
    </v-btn>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :right="true"
      :timeout="3000"
      :color="snack_color"
    >
      {{ snack_text }}
      <v-btn dark text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "pausarboton",

  data: () => ({
    snackbar: false,
    snack_color: "",
    snack_text: "",
    verpausa: false,
  }),
  computed: {
    Vbotonera() {
      return this.$store.getters.getiniciarBotonera;
    },
  },
  watch: {
    Vbotonera(value) {
      this.verpausa = false;
    },
  },
  methods: {
    pausa() {
      this.verpausa = true;
      this.$store.getters.getinstanciaPhone.hold();
    },
    reanudar() {
      this.verpausa = false;
      this.$store.getters.getinstanciaPhone.unhold();
    },
    go(msg, color) {
      this.snack_text = msg;
      this.snack_color = color;
      this.snackbar = true;
    },
  },
  props: {
    itemEntrada: {},
  },
};
</script>


<template >
  <v-container>
    <v-btn v-show="!mute" x-large fab color="success" @click="muteo()">
      <v-icon>mdi-microphone-off</v-icon>
    </v-btn>

    <v-btn color="warning" v-show="mute" x-large fab @click="desmuteo()">
      <v-icon>mdi-microphone</v-icon>
    </v-btn>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :right="true"
      :timeout="3000"
      :color="snack_color"
    >
      {{ snack_text }}
      <v-btn dark text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "mutear",

  data: () => ({
    snackbar: false,
    snack_color: "",
    snack_text: "",
    mute: false,
  }),
  computed: {
    Vbotonera() {
      return this.$store.getters.getiniciarBotonera;
    },
  },
  watch: {
    Vbotonera(value) {
      this.mute = false;
    },
  },
  methods: {
    muteo() {
      this.$store.getters.getinstanciaPhone.sipCall.muteAudio();
      this.mute = true;
    },
    desmuteo() {
      this.$store.getters.getinstanciaPhone.sipCall.unmuteAudio();
      this.mute = false;
    },
    go(msg, color) {
      this.snack_text = msg;
      this.snack_color = color;
      this.snackbar = true;
    },
  },
  props: {
    itemEntrada: {},
  },
};
</script>

